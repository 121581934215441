import React from "react";

import "./button.scss";

export const Button = ({ text, url, onClick, className = "" }) => {
  if (!url) {
    return (
      <button onClick={onClick} className={`my-button ${className}`}>{text}</button>
    );
  }
  return (
    <a className="my-button" href={url}>{text}</a>
  );
}
