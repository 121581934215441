import './styles/global.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from './pages/home';
import { BoatDetail } from './pages/boat-detail';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import Loader from './components/loader/loader';

function App() {
  return (
    <>
      <Loader />
      <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <ScrollToTop>
          <Switch>
            <Route path={`/boat-detail/:id`}>
              <BoatDetail />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
