import React, { useEffect } from "react";
import { useState } from "react";
import { SearchSelect } from "../search-select/search-select";
import searchIcon from "../../assets/images/search.svg";
import "./category-form.scss";
import * as Actions from "../../actions/actions"
import { connect } from "react-redux";
import motorYachtIcon from "../../assets/images/icon-motor-yacht.svg";
import expressCruiseIcon from "../../assets/images/icon-express-cruise.svg";
import centerConsoleIcon from "../../assets/images/icon-center-console.svg";
import sportFishingIcon from "../../assets/images/icon-sport-fishing.svg";
import sailingIcon from "../../assets/images/icon-sailing-yacht.svg";

import { DEFAULT_VALUE } from "../../utils/constants";

const categories = [
  { id: "Motor Yacht", label: "Motor Yacht", icon: motorYachtIcon },
  { id: "Express Cruisers", label: "Express Cruisers", icon: expressCruiseIcon },
  { id: "Sport Fishing", label: "Sport Fishing", icon: sportFishingIcon },
  { id: "Center Consoles", label: "Center Consoles", icon: centerConsoleIcon },
  { id: "Sailing Yachts", label: "Sailing Yachts", icon: sailingIcon },
];

const CategoryForm = ({ onSearch, stateCategory }) => {
  const [category, setCategory] = useState(DEFAULT_VALUE);

  useEffect(() => {
    setCategory(stateCategory);
  }, [stateCategory]);


  const handleOnSearch = () => {
    onSearch(category.id);
  };

  const handleOnDesktopSearch = (categoryId) => {
    const newCategory = categories.find(cat => cat.id === categoryId);
    if (newCategory.id !== category) {
      setCategory(newCategory.id);
      onSearch(newCategory.id);
    } else {
      // This will allow to toggle the category filter selection to a non-selected state.
      setCategory(DEFAULT_VALUE);
      onSearch({});
    }
  };

  return (
    <>
      <form className="my-category-form my-category-form--mobile" onSubmit={(e) => { e.preventDefault(); handleOnSearch(); }}>
        <SearchSelect
          placeholder="Select a category"
          options={categories}
          value={category}
          defaultValue={DEFAULT_VALUE}
          onChange={(value) => setCategory(value)}
        />
        <button type="submit" title="Search" className="my-category-form__submit"><img src={searchIcon} alt="Submit" /></button>
      </form>
      <div className="my-category-form--desktop">
        {categories.filter(cat => cat.id).map((option, index) => (
          <button
            className={`my-category-form__option ${option.id === category ? "my-category-form__option--active" : ""}`}
            key={option.id}
            onClick={() => handleOnDesktopSearch(option.id)}>
            <img
              alt="Boat Category"
              className="my-category-form__option__icon"
              src={option.icon}
              style={{
                height: option.id === "Sailing Yachts" ? '60px' : 'auto',
                width: option.id === "Sailing Yachts" ? '60px' : '100px',
              }}>
            </img>
            <span>{option.label}</span>
          </button>
        ))}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    stateCategory: state.category,
  };
};

const mapDispachToProps = dispatch => {
  return {
    onSearch: (category) => dispatch(Actions.triggerCategorySearch(category))
  };
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(CategoryForm);