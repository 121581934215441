import React from "react";
import closeIcon from "../../assets/images/close.svg"

import "./navbar.scss";
import { BASE_WEBSITE_URL, IG_URL, BLOG_MIAMI_URL } from "../../utils/constants";

const Navbar = ({ isOpen, onToggleMenu }) => {
  return (
    <nav className={`my-navbar ${isOpen ? "my-navbar--active" : ""}`}>
      <div className="my-navbar__header">
        <button
          className="my-navbar__close"
          type="button"
          onClick={onToggleMenu}
        >
          <img src={closeIcon} alt="Toggle Menu" />
        </button>
      </div>
      <ul className="my-navbar-list">
        <li className="my-navbar-list__link">
          <a href={`${BASE_WEBSITE_URL}/about`}>About us</a>
        </li>
        <li className="my-navbar-list__link">
          <a href={`${BASE_WEBSITE_URL}/sales`}>Yacht Sales</a>
        </li>
        <li className="my-navbar-list__link">
          <a href={`${BASE_WEBSITE_URL}/management`}>Yacht management</a>
        </li>
        <li className="my-navbar-list__link">
          <a href={`${BASE_WEBSITE_URL}/charters`}>Charters</a>
        </li>
        <li className="my-navbar-list__link">
          <a href={`${BASE_WEBSITE_URL}/services`}>Services</a>
        </li>
        <li className="my-navbar-list__link">
          <a href={BLOG_MIAMI_URL}>Blog</a>
        </li>
        <li className="my-navbar-list__link">
          <a href={IG_URL} target="_blank" rel="noreferrer">Gallery</a>
        </li>
        <li className="my-navbar-list__link">
          <a href={`${BASE_WEBSITE_URL}/contact`}>Contact us</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
