import React, { useState } from "react";

import "./gallery.scss";

import arrowLeft from "../../assets/images/arrow-left.svg";
import arrowRight from "../../assets/images/arrow-right.svg";
import { openInNewTab } from "../../utils/helpers";
import { IG_URL } from "../../utils/constants";

export const Gallery = ({ id, images, style, disableClick = false }) => {
  const [current, setCurrent] = useState(0);

  const onNext = () => {
    if (current < images.length - 1) {
      setCurrent(current + 1);
    } else {
      setCurrent(0);
    }
  }
  const onPrevious = () => {
    if (current > 0) {
      setCurrent(current - 1);
    } else {
      setCurrent(images.length - 1);
    }
  }

  return (
    <div id={id} style={style} className="my-gallery">
      <button className="my-gallery__prev" onClick={onPrevious}>
        <img src={arrowLeft} alt="Previous" />
      </button>
      <div className={`my-gallery-frame ${!disableClick ? "my-gallery-frame--clickable" : ""}`} onClick={() => !disableClick && openInNewTab(IG_URL)}>
        <div className="my-gallery-slider">
          <div className="my-gallery-photos" style={{ transform: `translateX(-${current}00%)` }}>
            {images.map(image => <img className="my-gallery-photos__img" key={image} src={image} alt="Gallery" />)}
          </div>
        </div>
      </div>
      <button className="my-gallery__next" onClick={onNext}>
        <img src={arrowRight} alt="Next" />
      </button>
      <div className="my-gallery-actions">
        <button className="my-gallery__button" onClick={onPrevious}>
          <img src={arrowLeft} alt="Previous" />
        </button>
        <button className="my-gallery__button" onClick={onNext}>
          <img src={arrowRight} alt="Next" />
        </button>
      </div>
    </div>
  );
}
