import { NUMBER_OF_ITEMS_BY_CATEGORY } from "./constants";

const categoryFilterParameters = {
  "Motor Yacht": {
    minPrice: 1000000,
    maxPrice: 2000000,
    builderIDList: [2492, 174, 4805, 870],
  },
  "Cruising Yacht": {
    minPrice: 1000000,
    maxPrice: 3000000,
    builderIDList: [2492, 174, 4805, 870],
  },
  "Sport Fisherman": {
    minPrice: 1000000,
    maxPrice: 2000000,
    builderIDList: [2684, 258, 4805, 870],
  },
  Boats: {
    minPrice: 300000,
    maxPrice: 1000000,
    builderIDList: [4768, 2980, 580, 307],
  },
  1: {
    minPrice: 100000,
    maxPrice: 500000,
    builderIDList: [250, 1272, 1178, 4768],
  },
};

const filterByPriceRange = ({ data, category }) =>
  data.filter(
    (item) =>
      item.priceNumber >= categoryFilterParameters[category].minPrice &&
      item.priceNumber <= categoryFilterParameters[category].maxPrice
  );

export const yachtFilters = ({ data, category }) => {
  const filteredDataByBuilder = data.filter((obj) =>
    categoryFilterParameters[category].builderIDList.includes(obj.builderID)
  );

  const uniqueBuilderIDs = {};

  const filteredDataByUniqueBuilder = filteredDataByBuilder.filter(
    (obj) =>
      !uniqueBuilderIDs[obj.builderID] &&
      (uniqueBuilderIDs[obj.builderID] = true)
  );

  let filteredData = filterByPriceRange({
    data: filteredDataByUniqueBuilder,
    category,
  });

  if (filteredData.length >= NUMBER_OF_ITEMS_BY_CATEGORY) {
    return filteredData.slice(0, NUMBER_OF_ITEMS_BY_CATEGORY);
  }

  filteredData = filterByPriceRange({
    data: filteredDataByBuilder,
    category,
  });

  if (filteredData.length >= NUMBER_OF_ITEMS_BY_CATEGORY) {
    return filteredData.slice(0, NUMBER_OF_ITEMS_BY_CATEGORY);
  }

  filteredData = filterByPriceRange({
    data,
    category,
  });

  if (filteredData.length >= NUMBER_OF_ITEMS_BY_CATEGORY) {
    return filteredData.slice(0, NUMBER_OF_ITEMS_BY_CATEGORY);
  }

  return data.slice(0, NUMBER_OF_ITEMS_BY_CATEGORY);
};
