import React from "react";
import closeIcon from "../../assets/images/close.svg"

import "./top-drawer.scss";

const TopDrawer = ({ isOpen, onToggleMenu, children }) => {
  return (
    <nav className={`my-top-drawer ${isOpen ? "my-top-drawer--active" : ""}`}>
      <div className="my-top-drawer__header">
        <button
          className="my-top-drawer__close"
          type="button"
          onClick={onToggleMenu}
        >
          <img src={closeIcon} alt="Toggle Menu" />
        </button>
      </div>
      <div className="my-top-drawer__content">
        {children}
      </div>
    </nav>
  );
};

export default TopDrawer;
