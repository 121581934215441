import React from "react";
import "@fontsource/open-sans";
import "@fontsource/junge"
import "./layout.scss";
import { Footer } from "../footer/footer";
import Header from "../header/header";
import { Banner } from "../banner/banner";

export const Layout = ({ children, title, bannerContent, hasBanner = true, bannerBottomAligned = true, hideBannerArrows = false }) => {
  return (
    <div>
      <Header />
      {hasBanner && <Banner title={title || "Sales"} isBottomAligned={bannerBottomAligned} hideArrows={hideBannerArrows}>{bannerContent}</Banner>}
      <div id="layout-content" className="my-layout__content">
        {children}
      </div>
      <Footer />
    </div>
  );
}
