import React from "react";

import "./search-radio-group.scss";

export const SearchRadioGroup = ({ options, value, onChange, labelPrefix }) => {
  return (
    <div className="my-search-radio-group">
      {options?.map(option =>
        <div className="my-search-radio-group__item" key={`radio-${option}`}>
          <input
            id={`${labelPrefix}-${option}`}
            type="radio"
            value={option}
            checked={option === value}
            onChange={(event) => onChange(event.target.value)}
          />
          <label htmlFor={`${labelPrefix}-${option}`}>
            {option}
          </label>
        </div>)}
    </div>
  );
}
