import React from "react";
import { BASE_WEBSITE_URL } from "../../utils/constants";

import "./dropdown.scss";

const getLinkURL = (url) => {
  if (url.indexOf("http") === 0) {
    return url;
  } else {
    return `${BASE_WEBSITE_URL}${url}`;
  }
};

export const Dropdown = ({ url, name, linkList }) => {
  if (!linkList || !linkList.length) {
    return (
      <div className="my-dropdown">
        <a className="my-dropdown__link" href={getLinkURL(url)}>{name}</a>
        <div className="my-dropdown__underscore" />
      </div>
    );
  }
  return (
    <div className="my-dropdown">
      <a className="my-dropdown__link" href={getLinkURL(url)}>{name}</a>
      <div className="my-dropdown__underscore" />
      <div className="my-dropdown-content">
        {linkList.map(link => <a key={link.name} href={`${getLinkURL(url)}#${link.url}`}>{link.name}</a>)}
      </div>
    </div>
  );
}
