import React from "react";
import Autocomplete from "@mui/material/Autocomplete";

import "./search-select.scss";

export const SearchSelect = ({
  label,
  placeholder,
  options,
  value,
  onChange,
}) => {
  return (
    <label className="my-search-select">
      {label}
      <Autocomplete
        value={value}
        placeholder={placeholder}
        options={options}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              placeholder={placeholder}
              {...params.inputProps}
              className="my-search-select__field"
            />
          </div>
        )}
        onChange={(event, value) => onChange(value)}
        sx={{
          width: "100%",
        }}
      />
    </label>
  );
};
