import React from "react";
import "./loader.scss"
import { connect } from "react-redux";

const Loader = ({ isLoading }) => {
  return (
    <>
      {isLoading && <div className="my-loader">
        <div className="my-loader__spinner" />
      </div>}
    </>
  );
}

const mapStateToProps = state => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispachToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(Loader);
