
// Imports
import axios from 'axios';

// Uses heroku proxy for production
const rootURL = process.env.NODE_ENV !== 'development' ? 'https://afternoon-tor-08689.herokuapp.com/https://api.yatcoboss.com' : '';

// Singleton instance
const api = axios.create({
  baseURL: `${rootURL}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Basic iALvWMqtQjpLX55yuOc9Q16QDA0+fPWTKS4mPvPNlX08VPyRzL6SfA=='
  },
  interceptors: {
    request: {
      use: (config) => {
        return config;
      },
    },
  },
});

// Get request with error handling
export const get = async (url, params) => {
  try {
    const response = await api.get(url, { params });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Post request with error handling
export const post = async (url, data) => {
  try {
    const response = await api.post(url, data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Put request with error handling
export const put = async (url, data) => {
  try {
    const response = await api.put(url, data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Delete request with error handling
export const remove = async (url, data) => {
  try {
    const response = await api.delete(url, data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};