import React from "react";
import "./paginator.scss";

export const Paginator = ({ currentPage, totalPages, onChange }) => {

  const onPrevPage = () => {
    if (currentPage > 1) {
      onChange(currentPage - 1);
    }
  };

  const onNextPage = () => {
    if (currentPage < totalPages) {
      onChange(currentPage + 1);
    }
  };

  const goToPage = (page) => {
    onChange(page);
  };

  const PageButton = ({ onClick, label }) => {
    return (
      <button
        key={label}
        className={`my-paginator__button ${isNaN(label) ? "my-paginator__button--arrow" : ""} ${currentPage === label ? "my-paginator__button--active" : ""}`}
        onClick={onClick} >{label}</button>
    );
  };

  const renderPages = () => {
    const pages = [];
    if (currentPage === totalPages) {
      return [<PageButton key={1} onClick={() => goToPage(1)} label={1} />];
    }
    for (let i = currentPage; i < currentPage + 3 && i < totalPages; i++) {
      pages.push(
        <PageButton key={i} onClick={() => goToPage(i)} label={i} />
      );
    }
    return pages;
  };

  return (
    <div className="my-paginator">
      {currentPage > 1 && <PageButton onClick={onPrevPage} label={"<"} />}
      {renderPages()}
      <span className="my-paginator__button">...</span>
      {totalPages > 1 && <PageButton onClick={() => goToPage(totalPages)} label={totalPages} />}
      <PageButton onClick={onNextPage} label={">"} />
    </div>
  );
}