import React, { useEffect } from "react";
import { useState } from "react";
import { SearchRadioGroup } from "../search-radio-group/search-radio-group";
import { SearchRange } from "../search-range/search-range";
import { SearchSelect } from "../search-select/search-select";
import searchIcon from "../../assets/images/search.svg";
import "./search-form.scss";
import { Button } from "../button/button";
import * as Actions from "../../actions/actions";
import { connect } from "react-redux";

import { SearchField } from "../search-field/search-field";
import { DEFAULT_VALUE } from "../../utils/constants";

const types = ["All", "Power", "Sail"];

const conditions = [
  { id: 1, label: "New" },
  { id: 2, label: "Used" },
];

const SearchForm = ({
  isDesktop,
  searchFilters,
  manufacturers,
  regions,
  countries,
  states,
  cities,
  searchType,
  fetchInitialLists,
  fetchCountries,
  fetchStates,
  fetchCities,
  onSearch,
}) => {
  const [type, setType] = useState(types[0]);
  const [manufacturer, setManufacturer] = useState(null);
  const [model, setModel] = useState(DEFAULT_VALUE);
  const [condition, setCondition] = useState(DEFAULT_VALUE);
  const [minYear, setMinYear] = useState(DEFAULT_VALUE);
  const [maxYear, setMaxYear] = useState(DEFAULT_VALUE);
  const [minLength, setMinLength] = useState(DEFAULT_VALUE);
  const [maxLength, setMaxLength] = useState(DEFAULT_VALUE);
  const [minPrice, setMinPrice] = useState(DEFAULT_VALUE);
  const [maxPrice, setMaxPrice] = useState(DEFAULT_VALUE);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [region, setRegion] = useState(null);

  const onSubmitHandler = () => {

    const filters = {
      type,
      manufacturer,
      region,
      country,
      state,
      city,
      model,
      condition,
      minLength,
      maxLength,
      minPrice,
      maxPrice,
      minYear,
      maxYear,
    };

    onSearch(filters);
  }

  useEffect(() => {
    setManufacturer(searchFilters.manufacturer);
    setModel(searchFilters.model);
    setCondition(searchFilters.condition);
    setMinYear(searchFilters.minYear);
    setMaxYear(searchFilters.maxYear);
    setMinLength(searchFilters.minLength);
    setMaxLength(searchFilters.maxLength);
    setMinPrice(searchFilters.minPrice);
    setMaxPrice(searchFilters.maxPrice);
    setRegion(searchFilters.region);
    setCountry(searchFilters.country);
    setState(searchFilters.state);
    setCity(searchFilters.city);
  }, [searchFilters]);

  useEffect(() => {
    setType(searchType);
  }, [searchType]);

  useEffect(() => {
    fetchInitialLists();
  }, [fetchInitialLists]);

  useEffect(() => {
    fetchCountries(region);
    setCountry(null);
    setState(null);
    setCity(null);
  }, [region, fetchCountries]);

  useEffect(() => {
    fetchStates(country);
    setState(null);
    setCity(null);
  }, [country, fetchStates]);

  useEffect(() => {
    fetchCities(state);
    setCity(null);
  }, [state, fetchCities]);

  useEffect(() => {
    if (model) setManufacturer(null);
  }, [model]);

  useEffect(() => {
    if (manufacturer) setModel('');
  }, [manufacturer]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitHandler();
      }}
      className="my-search-form"
    >
      <div className="my-search-form-content">
        <div className="my-search-form-container-top">
          <SearchRadioGroup
            labelPrefix={isDesktop ? "desktop" : ""}
            options={types}
            value={type}
            onChange={(value) => setType(value)}
          />
          <button
            type="submit"
            title="Search"
            className="my-search-form-submit__desktop"
          >
            <img src={searchIcon} alt="Submit" />
          </button>
        </div>
        <div className="my-search-form-container-middle">
          <SearchSelect
            label="Manufacturer"
            placeholder="Select a Manufacturer"
            options={manufacturers}
            value={manufacturer}
            onChange={(value) => setManufacturer(value)}
          />
          <SearchField
            label="Model"
            placeholder="Select a Model"
            value={model}
            onChange={(value) => setModel(value)}
          />
          <SearchSelect
            label="Condition"
            placeholder="Yacht Condition"
            options={conditions}
            value={condition}
            onChange={(value) => setCondition(value)}
          />
          <SearchRange
            label="Year"
            minPlaceholder="From"
            maxPlaceholder="To"
            minValue={minYear || ""}
            maxValue={maxYear || ""}
            onChange={(newMin, newMax) => {
              setMinYear(newMin);
              setMaxYear(newMax);
            }}
          />
          <SearchRange
            label="Length"
            minPlaceholder="From"
            maxPlaceholder="To"
            minValue={minLength || ""}
            maxValue={maxLength || ""}
            onChange={(newMin, newMax) => {
              setMinLength(newMin);
              setMaxLength(newMax);
            }}
          />
        </div>
        <div className="my-search-form-container-bottom">
          <SearchRange
            label="Price (USD)"
            minPlaceholder="Min"
            maxPlaceholder="Max"
            minValue={minPrice || ""}
            maxValue={maxPrice || ""}
            onChange={(newMin, newMax) => {
              setMinPrice(newMin);
              setMaxPrice(newMax);
            }}
          />
          <SearchSelect
            label="Region"
            placeholder="Select a Region"
            options={regions}
            value={region}
            onChange={(value) => setRegion(value)}
          />
          <SearchSelect
            label="Country"
            placeholder="Select a Country"
            options={countries}
            value={country}
            onChange={(value) => setCountry(value)}
          />
          <SearchSelect
            label="State"
            placeholder="Select a State"
            options={states}
            value={state}
            onChange={(value) => setState(value)}
          />
          <SearchSelect
            label="City"
            placeholder="Select a City"
            options={cities}
            value={city}
            onChange={(value) => setCity(value)}
          />
        </div>
      </div>
      <Button className="my-search-form-submit__mobile" text="Apply" />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    manufacturers: state.manufacturers,
    regions: state.regions,
    countries: state.countries,
    states: state.states,
    cities: state.cities,
    searchFilters: state.searchFilters,
    searchType: state.searchType,
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    fetchInitialLists: () => dispatch(Actions.fetchInitialLists()),
    fetchCountries: (region) => dispatch(Actions.fetchCountries(region)),
    fetchStates: (country) => dispatch(Actions.fetchStates(country)),
    fetchCities: (state) => dispatch(Actions.fetchCities(state)),
    onToggleSearchDrawer: () => dispatch(Actions.toggleSearchDrawer()),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(SearchForm);
