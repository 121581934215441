import React from "react";

import "./search-field.scss";

export const SearchField = ({ label, placeholder, name, value, onChange }) => {
  return (
    <label className="my-search-field">
      {label}
      <input
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(event) => { onChange(event.target.value) }} />
    </label >
  );
}
