import * as React from "react"
import { useState } from "react";
import { FORMSPREE_ENDPOINT } from "../../utils/constants";
import "./contact-form.scss";

const ContactForm = ({ pagePath }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const handleSubmitted = () => {
    setSubmitted(true);
    setName(null);
    setPhone(null);
    setEmail(null);
    setMessage(null);
  }

  return (
    <form
      onSubmit={handleSubmitted}
      className="my-contact-form"
      action={FORMSPREE_ENDPOINT}
      method="POST">
      <div className="my-contact-form-content">
        <div className="my-contact-form-fields">
          <input
            placeholder="Name"
            name="name"
            type="text"
            value={name}
            onChange={(event) => setName(event.target.value)} />
          <input
            placeholder="Phone"
            name="phone"
            type="tel"
            value={phone}
            onChange={(event) => setPhone(event.target.value)} />
          <input
            placeholder="Email"
            name="email"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)} />
          <textarea
            rows="5"
            name="message"
            placeholder="Write your message here"
            value={message}
            onChange={(event) => setMessage(event.target.value)} />
        </div>
      </div>
      <div className="my-contact-form-actions">
        <input
          disabled={!name || !phone || !email || !message}
          className="my-contact-form__submit"
          type="submit"
          value="Send message" />
        {submitted && <span className="my-contact-form__message">Thank you, we will contact you shortly.</span>}
      </div>
    </form >
  );
}

export default ContactForm;