import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Layout } from "../components/layout/layout";
import "./boat-detail.scss";
import { ReactComponent as ArrowIcon } from "../assets/images/arrow-left.svg";
import { ReactComponent as LengthIcon } from "../assets/images/length.svg";
import { ReactComponent as LocationIcon } from "../assets/images/location-3.svg";
import { ReactComponent as GearIcon } from "../assets/images/gear.svg";
import { ReactComponent as FlagIcon } from "../assets/images/flag.svg";
import { ReactComponent as PriceIcon } from "../assets/images/price.svg";
import { ReactComponent as YearIcon } from "../assets/images/year.svg";
import { Gallery } from "../components/gallery/gallery";
import defaultImage from "../assets/images/logo_default.png";
import Boats from "../api/Boats";
import FormContainer from "../components/form-container/form-container"

export const BoatDetail = () => {
  let { id } = useParams();
  const [boat, setBoat] = useState({});
  // Effect to fetch data
  useEffect(() => {
    const fetchBoat = async () => {
      const data = await Boats.getById(id);
      setBoat(data);
    }
    fetchBoat(id);
  }, [id]);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  let galleryPictures = boat.galleryPictures;
  if (windowSize.current[0] <= 768) {
    galleryPictures = boat.mobileGalleryPictures;
  }

  return (
    <Layout title={"Yacht Sales"} bannerBottomAligned={false} hideBannerArrows={false}>
      <div className="my-boat-detail">
        <Link className="my-boat-detail__link" to="/"><ArrowIcon stroke="#07215C" height={12} /> Back to search</Link>
        <div className="my-boat-detail-head">
          <img className="my-boat-detail__image" src={boat.pictureURL || defaultImage} alt="Boat banner" />
          <div className="my-boat-detail-info">
            <h1 className="my-boat-detail__title">{boat.name}</h1>
            <div className="my-boat-detail-items">
              <div className="my-boat-detail-item">
                <div className="my-boat-detail-item__icon">
                  <PriceIcon />
                </div>
                <p className="my-boat-detail-item__text">{boat.price}</p>
              </div>
              <div className="my-boat-detail-item">
                <div className="my-boat-detail-item__icon">
                  <GearIcon />
                </div>
                <p className="my-boat-detail-item__text">{boat.manufacturer}</p>
              </div>
              <div className="my-boat-detail-item">
                <div className="my-boat-detail-item__icon">
                  <LengthIcon />
                </div>
                <p className="my-boat-detail-item__text">{boat.length}</p>
              </div>
              <div className="my-boat-detail-item">
                <div className="my-boat-detail-item__icon">
                  <YearIcon />
                </div>
                <p className="my-boat-detail-item__text">{boat.year}</p>
              </div>
              <div className="my-boat-detail-item">
                <div className="my-boat-detail-item__icon">
                  <LocationIcon />
                </div>
                <p className="my-boat-detail-item__text">{boat.location}</p>
              </div>
              <div className="my-boat-detail-item">
                <div className="my-boat-detail-item__icon">
                  <FlagIcon />
                </div>
                <p className="my-boat-detail-item__text">Flag of Registry: {boat.flag}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="my-boat-detail-content">
          <div dangerouslySetInnerHTML={{ __html: boat.description }} />
        </div>
        <div className="my-boat-detail-specs">
          <h2 className="my-boat-detail-specs__title">Specifications</h2>
          <div className="my-boat-detail-specs-content">
            <div className="my-boat-detail-specs-section">
              <span className="my-boat-detail-specs__item"><strong>LOA: </strong>{boat.length}</span>
              <span className="my-boat-detail-specs__item"><strong>Type: </strong>{boat.type}</span>
              <span className="my-boat-detail-specs__item"><strong>Year: </strong>{boat.year}</span>
              <span className="my-boat-detail-specs__item"><strong>Beam: </strong>{boat.beam}</span>
              <span className="my-boat-detail-specs__item"><strong>Draft Max: </strong>{boat.draftMax}</span>
              <span className="my-boat-detail-specs__item"><strong>Draft Min: </strong>{boat.draftMin}</span>
            </div>
            <div className="my-boat-detail-specs-section">
              <span className="my-boat-detail-specs__item"><strong>Cabins: </strong>{boat.cabins}</span>
              <span className="my-boat-detail-specs__item"><strong>Sleep: </strong>{boat.sleeps}</span>
              <span className="my-boat-detail-specs__item"><strong>Single Berths: </strong>{boat.singleBerths}</span>
              <span className="my-boat-detail-specs__item"><strong>King Berths: </strong>{boat.kingBerths}</span>
              <span className="my-boat-detail-specs__item"><strong>Full Beam Master: </strong>{boat.fullBeamMaster}</span>
              <span className="my-boat-detail-specs__item"><strong>Crew Cabins: </strong>{boat.crewCabins}</span>
              <span className="my-boat-detail-specs__item"><strong>Crew Sleeps: </strong>{boat.crewSleeps}</span>
            </div>
            <div className="my-boat-detail-specs-section">
              <span className="my-boat-detail-specs__item"><strong>Maximum Speed: </strong>{boat.maxSpeed}</span>
              <span className="my-boat-detail-specs__item"><strong>Cruise Speed: </strong>{boat.cruiseSpeed}</span>
              <span className="my-boat-detail-specs__item"><strong>Fuel type: </strong>{boat.fuelType}</span>
              <span className="my-boat-detail-specs__item"><strong>Hull Material: </strong>{boat.hullMaterial}</span>
              <span className="my-boat-detail-specs__item"><strong>Hull Shape: </strong>{boat.hullShape}</span>
            </div>
          </div>
        </div>
        <div className="my-boat-detail__gallery">
          <Gallery images={galleryPictures || []} disableClick />
        </div>
        <div className="my-boat-detail-form">
          <FormContainer pagePath="" />
        </div>
      </div>
    </Layout >
  );
}