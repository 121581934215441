import React from "react";

import "./footer.scss";
import phoneIcon from "../../assets/images/phone.svg"
import locationIcon from "../../assets/images/location.svg"
import mailIcon from "../../assets/images/mail.svg"
import fbIcon from "../../assets/images/facebook.svg"
import igIcon from "../../assets/images/instagram.svg"
import MCALogo from "../../assets/images/mca-logo.svg";
import ABYCLogo from "../../assets/images/abyc-logo.svg";
import IYBALogo from "../../assets/images/iyba-logo.svg";
import { BASE_WEBSITE_URL, FB_URL, IG_URL } from "../../utils/constants";

export const Footer = () => {
  return (
    <footer className="my-footer">
      <div>
        <div className="my-footer__title">Contact us</div>
      </div>
      <div className="my-footer-links">
        <a className="my-footer-link" href="tel: (786) 354-0026">
          <img className="my-footer-link__icon" src={phoneIcon} alt="phone" />
          <span>(786) 354-0026</span>
        </a>
        <div className="my-footer-link">
          <img className="my-footer-link__icon" src={locationIcon} alt="location" />
          <div className="my-footer-link__text">
            <span>2550 S Bayshore Dr.</span>
            <span>Coconut Grove, FL 33133</span>
          </div>
        </div>
        <a className="my-footer-link" href="mailto: info@miamiyatching.net">
          <img className="my-footer-link__icon" src={mailIcon} alt="mail" />
          <span>info@miamiyatching.net</span>
        </a>
        <div>
          <a href={FB_URL} target="_blank" rel="noreferrer">
            <img className="my-footer__social" src={fbIcon} alt="fb" />
          </a>
          <a href={IG_URL} target="_blank" rel="noreferrer">
            <img className="my-footer__social" src={igIcon} alt="ig" />
          </a>
        </div>
      </div>
      <div>
        <div className="my-footer-endorsed">
          <div>Endorsed by</div>
          <div className="my-footer-endorsed-logos">
            <img src={ABYCLogo} alt="ABYC Logo" />
            <img src={MCALogo} alt="MCA Logo" />
            <img src={IYBALogo} alt="IYBA Logo" />
          </div>
        </div>
        <a className="my-footer__subtitle" href={`${BASE_WEBSITE_URL}/privacy-policy`}>Privacy policy</a>
        <div className="my-footer__rights">© {new Date().getFullYear()} by Miami Yachting LLC. All Rights Reserved</div>
      </div>
    </footer>
  );
}
