import React from "react";
import "./filters-form.scss";
import * as Actions from "../../actions/actions"
import { connect } from "react-redux";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
const FiltersForm = ({ filters, categoryFilters, clearFilters, clearFilter }) => {

  const onClearFilters = () => {
    clearFilters();
    // Scroll to results section
    const element = document.getElementById('home-content');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const allFilters = { ...filters, ...categoryFilters };

  const camelToTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, function (str) { return str.toUpperCase(); })
  };

  const capitalize = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace(/^./, function (str) { return str.toUpperCase(); })
  };

  const getFilterLabel = (filterId) => {
    const filter = allFilters[filterId];
    if (filter.label) {
      return capitalize(filter.label);
    } else if (typeof filter === 'boolean') {
      return camelToTitleCase(filterId);
    }
    return `${camelToTitleCase(filterId)}: ${filter}`;
  };

  return (
    <div className="my-filters-form">
      {Object.keys(allFilters).filter(key => allFilters[key] && key !== "myCategory").map((filterId, index) => (
        <button
          className="my-filters-form__option"
          key={filterId}
          onClick={() => clearFilter(filterId.id ?? filterId)}
        >
          <span>{getFilterLabel(filterId)}</span>
          <CloseIcon className="my-filters-form__icon" />
        </button>
      ))
      }
      <button
        className="my-filters-form__clear"
        onClick={onClearFilters}
      >
        <span>Clear all</span>
      </button>
    </div >
  )
}

const mapStateToProps = state => {
  return {
    filters: state.searchFilters,
    categoryFilters: state.categoryFilters,
  };
};

const mapDispachToProps = dispatch => {
  return {
    clearFilters: () => dispatch(Actions.clearAllFilters()),
    clearFilter: (filterId) => dispatch(Actions.clearFilter(filterId))
  };
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(FiltersForm);