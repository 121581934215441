import React, { useState, useCallback } from "react";

import "./header.scss";
import logo from "../../assets/images/logo-white.png";
import logoWP from "../../assets/images/logo-white.webp";
import menuIcon from "../../assets/images/menu.svg";
import Navbar from "../navbar/navbar";
import { Dropdown } from "../dropdown/dropdown";
import { BASE_WEBSITE_URL, PAGES_DATA } from "../../utils/constants";
import { connect } from "react-redux";
import TopDrawer from "../top-drawer/top-drawer";
import * as Actions from "../../actions/actions"
import SearchForm from "../search-form/search-form";

const Header = (props) => {
  const {onSearch, onToggleSearchDrawer} = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleOnSearch = useCallback((filters) => {
    onSearch(filters);

    const element = document.getElementById("home-content");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    onToggleSearchDrawer();

  }, [onSearch, onToggleSearchDrawer])

  return (
    <header className="my-header">
      <a href={`${BASE_WEBSITE_URL}/`}>
        <picture>
          <source srcSet={logoWP} type="image/webp" />
          <img className="my-header__logo" src={logo} alt="Miami Yachting Logo" />
        </picture>
      </a>
      <nav className="my-header__links">
        {PAGES_DATA.slice(2).map(page => <Dropdown key={page.title} url={page.url} name={page.title} linkList={page.items} />)}
      </nav>
      <button
        type="button"
        className="my-header__menu"
        onClick={handleToggleMenu}
      >
        <img src={menuIcon} alt="Toggle Menu" />
      </button>
      <Navbar isOpen={menuOpen} onToggleMenu={handleToggleMenu} />
      <TopDrawer isOpen={props.searchDrawerOpen} onToggleMenu={props.onToggleSearchDrawer}>
        <SearchForm onSearch={handleOnSearch}/>
      </TopDrawer>
    </header>
  );
}

const mapStateToProps = state => {
  return {
    searchDrawerOpen: state.searchDrawerOpen
  };
};

const mapDispachToProps = dispatch => {
  return {
    onSearch: (filters) =>
      dispatch(Actions.triggerFiltersSearch({ page: 1, filters })),
    onToggleSearchDrawer: () => dispatch(Actions.toggleSearchDrawer())
  };
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(Header);
