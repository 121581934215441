const initialState = {
  isLoading: false,
  searchDrawerOpen: false,
  manufacturers: [],
  regions: [],
  countries: [],
  states: [],
  cities: [],
  searchType: "All",
  searchFilters: {
    manufacturer: null,
    model: null,
    condition: null,
    minYear: null,
    maxYear: null,
    minLength: null,
    maxLength: null,
    minPrice: null,
    maxPrice: null,
    region: null,
    country: null,
    state: null,
    city: null,
  },
  category: null,
  page: 1,
  sortBy: null,
  searchResults: [],
  featuredBoats: [],
  boatDetail: {},
  isPagerVisible: true,
  isFirstLoad: true,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case "TOGGLE_SEARCH_DRAWER":
      newState.searchDrawerOpen = !state.searchDrawerOpen;
      break;

    case "FETCH_INITIAL_LISTS":
      newState.isLoading = true;
      break;

    case "FETCH_INITIAL_LISTS_SUCCESS":
      newState.isLoading = false;
      newState.manufacturers = [...action.payload.manufacturers];
      newState.regions = [...action.payload.regions];
      break;

    case "FETCH_INITIAL_LISTS_ERROR":
      newState.isLoading = false;
      break;

    case "FETCH_COUNTRIES":
      newState.isLoading = true;
      break;

    case "FETCH_COUNTRIES_SUCCESS":
      newState.isLoading = false;
      newState.countries = [...action.payload];
      newState.states = [];
      newState.cities = [];
      break;

    case "FETCH_COUNTRIES_ERROR":
      newState.isLoading = false;
      break;

    case "FETCH_STATES":
      newState.isLoading = true;
      break;

    case "FETCH_STATES_SUCCESS":
      newState.isLoading = false;
      newState.states = [...action.payload];
      newState.cities = [];
      break;

    case "FETCH_STATES_ERROR":
      newState.isLoading = false;
      break;

    case "FETCH_CITIES":
      newState.isLoading = true;
      break;

    case "FETCH_CITIES_SUCCESS":
      newState.isLoading = false;
      newState.cities = [...action.payload];
      break;

    case "FETCH_CITIES_ERROR":
      newState.isLoading = false;
      break;

    case "TRIGGER_FILTERS_SEARCH":
      newState.isLoading = true;
      const { type, ...filters } = action.payload.filters;
      newState.searchFilters = filters;
      newState.searchType = type;
      newState.page = 1;
      newState.isPagerVisible = true;
      newState.isFirstLoad = false;
      break;

    case "TRIGGER_FIRST_LOAD_SEARCH":
      newState.isLoading = true;
      newState.isPagerVisible = false;
      break;

    case "ADD_SORT_FIELD_PARAMETER":
      newState.isLoading = true;
      newState.sortBy = action.payload;
      newState.page = 1;
      break;

    case "TRIGGER_CATEGORY_SEARCH":
      newState.isLoading = true;
      newState.category = action.payload;
      newState.page = 1;
      break;

    case "FETCH_BOATS":
      newState.isLoading = true;
      break;

    case "FETCH_BOATS_SUCCESS":
      newState.isLoading = false;
      newState.searchResults = action.payload;
      break;

    case "FETCH_BOATS_ERROR":
      newState.isLoading = false;
      break;

    case "FETCH_FEATURED":
      newState.isLoading = true;
      break;

    case "FETCH_FEATURED_SUCCESS":
      newState.isLoading = false;
      newState.featuredBoats = action.payload;
      break;

    case "FETCH_FEATURED_ERROR":
      newState.isLoading = false;
      break;

    case "GO_TO_RESULTS_PAGE":
      newState.isLoading = true;
      newState.page = action.payload;
      break;

    case "CLEAR_ALL_FILTERS":
      newState.isLoading = true;
      newState.page = 1;
      newState.searchFilters = {};
      newState.category = null;
      break;

    case "CLEAR_FILTER":
      newState.isLoading = true;
      const newSearchFilters = { ...newState.searchFilters };
      newSearchFilters[action.payload] = null;
      newState.page = 1;
      newState.searchFilters = newSearchFilters;
      break;

    default:
      break;
  }
  return newState;
};

export default reducer;
