export const FETCH_INITIAL_LISTS = "FETCH_INITIAL_LISTS";
export const FETCH_INITIAL_LISTS_SUCCESS = "FETCH_INITIAL_LISTS_SUCCESS";
export const FETCH_INITIAL_LISTS_ERROR = "FETCH_INITIAL_LISTS_ERROR";
export const FETCH_BOATS = "FETCH_BOATS";
export const FETCH_BOATS_SUCCESS = "FETCH_BOATS_SUCCESS";
export const FETCH_BOATS_ERROR = "FETCH_BOATS_ERROR";
export const FETCH_FEATURED = "FETCH_FEATURED";
export const FETCH_FEATURED_SUCCESS = "FETCH_FEATURED_SUCCESS";
export const FETCH_FEATURED_ERROR = "FETCH_FEATURED_ERROR";
export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const FETCH_COUNTRIES_ERROR = "FETCH_COUNTRIES_ERROR";
export const FETCH_STATES = "FETCH_STATES";
export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS";
export const FETCH_STATES_ERROR = "FETCH_STATES_ERROR";
export const FETCH_CITIES = "FETCH_CITIES";
export const FETCH_CITIES_SUCCESS = "FETCH_CITIES_SUCCESS";
export const FETCH_CITIES_ERROR = "FETCH_CITIES_ERROR";
export const ADD_SORT_FIELD_PARAMETER = "ADD_SORT_FIELD_PARAMETER";
export const TOGGLE_SEARCH_DRAWER = "TOGGLE_SEARCH_DRAWER";
export const TRIGGER_FILTERS_SEARCH = "TRIGGER_FILTERS_SEARCH";
export const TRIGGER_CATEGORY_SEARCH = "TRIGGER_CATEGORY_SEARCH";
export const TRIGGER_FIRST_LOAD_SEARCH = "TRIGGER_FIRST_LOAD_SEARCH";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const GO_TO_RESULTS_PAGE = "GO_TO_RESULTS_PAGE";

export const fetchInitialLists = () => ({
  type: FETCH_INITIAL_LISTS,
});
export const fetchInitialListsSuccess = (data) => ({
  type: FETCH_INITIAL_LISTS_SUCCESS,
  payload: data,
});
export const fetchInitialListsError = (error) => ({
  type: FETCH_INITIAL_LISTS_ERROR,
  payload: error,
});
export const fetchFeaturedBoats = () => ({
  type: FETCH_FEATURED,
});
export const fetchFeaturedBoatsSuccess = (data) => ({
  type: FETCH_FEATURED_SUCCESS,
  payload: data,
});
export const fetchFeaturedBoatsError = (error) => ({
  type: FETCH_FEATURED_ERROR,
  payload: error,
});
export const fetchBoats = () => ({
  type: FETCH_BOATS,
});
export const fetchBoatsSuccess = (data) => ({
  type: FETCH_BOATS_SUCCESS,
  payload: data,
});
export const fetchBoatsError = (error) => ({
  type: FETCH_BOATS_ERROR,
  payload: error,
});

export const fetchCountries = (region) => ({
  type: FETCH_COUNTRIES,
  payload: region,
});
export const fetchCountriesSuccess = (data) => ({
  type: FETCH_COUNTRIES_SUCCESS,
  payload: data,
});
export const fetchCountriesError = (error) => ({
  type: FETCH_COUNTRIES_ERROR,
  payload: error,
});

export const fetchStates = (country) => ({
  type: FETCH_STATES,
  payload: country,
});
export const fetchStatesSuccess = (data) => ({
  type: FETCH_STATES_SUCCESS,
  payload: data,
});
export const fetchStatesError = (error) => ({
  type: FETCH_STATES_ERROR,
  payload: error,
});
export const fetchCities = (state) => ({
  type: FETCH_CITIES,
  payload: state,
});
export const fetchCitiesSuccess = (data) => ({
  type: FETCH_CITIES_SUCCESS,
  payload: data,
});
export const fetchCitiesError = (error) => ({
  type: FETCH_CITIES_ERROR,
  payload: error,
});
export const addSortParameter = (parameter) => ({
  type: ADD_SORT_FIELD_PARAMETER,
  payload: parameter,
});
export const toggleSearchDrawer = () => ({
  type: TOGGLE_SEARCH_DRAWER,
});
export const triggerFiltersSearch = (filters) => ({
  type: TRIGGER_FILTERS_SEARCH,
  payload: filters,
});
export const triggerCategorySearch = (category) => ({
  type: TRIGGER_CATEGORY_SEARCH,
  payload: category,
});
export const triggerFirstLoadSearch = () => ({
  type: TRIGGER_FIRST_LOAD_SEARCH,
});
export const clearAllFilters = () => ({
  type: CLEAR_ALL_FILTERS,
});
export const clearFilter = (filterId) => ({
  type: CLEAR_FILTER,
  payload: filterId,
});

export function goToResultsPage(page) {
  return {
    type: GO_TO_RESULTS_PAGE,
    payload: page,
  };
}
