import React, { useState } from "react";
import "./banner.scss"

import arrowsIcon from "../../assets/images/arrows.svg";
import largeBanner from "../../assets/images/sales-banner-lg.png";
import largeBannerWP from "../../assets/images/sales-banner-lg.webp";
import bannerImage from "../../assets/images/sales-banner.png";
import bannerWP from "../../assets/images/sales-banner.webp";
import handleViewport from "react-in-viewport";

const BannerRaw = ({ title, isBottomAligned, hideArrows, children, inViewport, forwardedRef }) => {

  const [showTitle, setShowTitle] = useState(false);
  if (inViewport && !showTitle) {
    setShowTitle(true);
  }

  let height = children ? "100vh" : "70vh";

  return (
    <div className={`my-banner my-banner--bottom ${!children ? "my-banner--small" : ""}`} id="top" ref={forwardedRef}>
      <picture>
        <source media="(min-width:480px)" srcSet={largeBannerWP} type="image/webp" />
        <source media="(min-width:480px)" srcSet={largeBanner} />
        <source srcSet={bannerWP} type="image/webp" />
        <img style={{ height }} className={`my-banner__img ${isBottomAligned ? "my-banner__img--bottom" : "my-banner__img--center"}`} src={bannerImage} alt="Boat banner" />
      </picture>
      <div className={`my-banner-content ${true ? "my-banner-content--bottom" : ""} ${hideArrows || !children ? "my-banner-content--center" : ""}`}>
        <div></div>
        {title &&
          <h1 className={`my-banner__title ${showTitle ? "my-banner__title--show" : ""}`}>{title}</h1>}
        {children && <div className={`my-banner-items-container ${showTitle ? "my-banner-items-container--show" : ""}`}>
          <div className="my-banner-items__border" />
          <div className="my-banner-items">
            {children}
          </div>
        </div>}
        {!hideArrows && <a className={`my-banner__arrows`} href={`#layout-content`}>
          <img src={arrowsIcon} alt="Go down" />
        </a>}
      </div>
    </div>
  );
}

export const Banner = handleViewport(BannerRaw, { rootMargin: "0px" }, { disconnectOnLeave: true })
