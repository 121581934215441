import { takeLatest, put, call, select } from "redux-saga/effects";
import {
  fetchBoatsSuccess,
  fetchBoatsError,
  fetchFeaturedBoatsError,
  fetchFeaturedBoatsSuccess,
  fetchInitialListsSuccess,
  fetchInitialListsError,
  fetchCountriesSuccess,
  fetchCountriesError,
  fetchStatesError,
  fetchStatesSuccess,
  fetchCitiesError,
  fetchCitiesSuccess,
} from "../actions/actions";
import Boats from "../api/Boats";
import VesselFilter from "../api/FilterOptions";
import { CATEGORIES, CATEGORY_SAILING_YACHTS } from "../utils/constants";
import { yachtFilters } from "../utils/filters";

const getFilters = (state) => state.searchFilters;
const getCategory = (state) => state.category;
const getType = (state) => state.searchType;
const getSortBy = (state) => state.sortBy;
const getPage = (state) => state.page;

function* fetchInitialListsSaga() {
  try {
    const manufacturers = yield call(VesselFilter.getBuilders);
    const regions = yield call(VesselFilter.getRegions);
    const payload = {
      regions: regions.results,
      manufacturers: manufacturers.results,
    };
    yield put(fetchInitialListsSuccess(payload));
  } catch (error) {
    yield put(fetchInitialListsError(error));
  }
}

function* fetchBoatsSaga() {
  try {
    const filters = yield select(getFilters);
    const category = yield select(getCategory);
    const type = yield select(getType);
    const sortBy = yield select(getSortBy);
    const page = yield select(getPage);
    const data = yield call(
      Boats.getAll,
      page,
      filters,
      category,
      type,
      sortBy
    );
    yield put(fetchBoatsSuccess(data));
  } catch (error) {
    console.log("error", error);
    yield put(fetchBoatsError(error));
  }
}

function* fetchFeaturedBoatsSaga() {
  try {
    const data = yield call(Boats.getFeatured);
    yield put(fetchFeaturedBoatsSuccess(data));
  } catch (error) {
    yield put(fetchFeaturedBoatsError(error));
  }
}

function* fetchCountriesSaga(action) {
  try {
    const countries = yield call(VesselFilter.getCountries, action.payload.id);
    yield put(fetchCountriesSuccess(countries.results));
  } catch (error) {
    yield put(fetchCountriesError(error));
  }
}

function* fetchStatesSaga(action) {
  try {
    const states = yield call(VesselFilter.getStates, action.payload.id);
    yield put(fetchStatesSuccess(states.results));
  } catch (error) {
    yield put(fetchStatesError(error));
  }
}

function* fetchCitiesSaga(action) {
  try {
    const cities = yield call(VesselFilter.getCities, action.payload.id);
    yield put(fetchCitiesSuccess(cities.results));
  } catch (error) {
    yield put(fetchCitiesError(error));
  }
}

function* fetchBoatsFirstLoadSaga() {
  try {
    let filteredData = { results: [] };
    const data = yield call(Boats.getFirstLoadData);

    const dataVesselType2 = data.results.filter(
      (item) => item.vesselType === 2
    );

    for (const categoryItem of CATEGORIES) {
      const filteredDataByCategory = dataVesselType2.filter(
        (item) => item.mainCategoryText === categoryItem.category
      );

      filteredData.results.push(
        ...yachtFilters({
          data: filteredDataByCategory,
          category: categoryItem.category,
        })
      );
    }

    const dataVesselType1 = data.results.filter(
      (item) => item.vesselType === 1
    );

    filteredData.results.push(
      ...yachtFilters({
        data: dataVesselType1,
        category: CATEGORY_SAILING_YACHTS,
      })
    );

    yield put(
      fetchBoatsSuccess({
        ...filteredData,
        totalCount: filteredData.results.length,
        totalPages: 1,
        currentPage: 1,
      })
    );
  } catch (error) {
    console.log("error", error);
    yield put(fetchBoatsError(error));
  }
}

export function* watchSearch() {
  yield takeLatest("FETCH_INITIAL_LISTS", fetchInitialListsSaga);
  yield takeLatest("FETCH_BOATS", fetchBoatsSaga);
  yield takeLatest("FETCH_FEATURED", fetchFeaturedBoatsSaga);
  yield takeLatest("FETCH_COUNTRIES", fetchCountriesSaga);
  yield takeLatest("FETCH_STATES", fetchStatesSaga);
  yield takeLatest("FETCH_CITIES", fetchCitiesSaga);
  yield takeLatest("TRIGGER_FILTERS_SEARCH", fetchBoatsSaga);
  yield takeLatest("TRIGGER_CATEGORY_SEARCH", fetchBoatsSaga);
  yield takeLatest("TRIGGER_FIRST_LOAD_SEARCH", fetchBoatsFirstLoadSaga);
  yield takeLatest("ADD_SORT_FIELD_PARAMETER", fetchBoatsSaga);
  yield takeLatest("GO_TO_RESULTS_PAGE", fetchBoatsSaga);
  yield takeLatest("CLEAR_ALL_FILTERS", fetchBoatsSaga);
  yield takeLatest("CLEAR_FILTER", fetchBoatsSaga);
}
