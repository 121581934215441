import React from "react";
import "./boat-card.scss";
import boatDefaultIMG from "../../assets/images/boat-default.png";
import { Link } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../assets/images/location-2.svg";
import { ReactComponent as PriceIcon } from "../../assets/images/price.svg";
import { ReactComponent as YearIcon } from "../../assets/images/year.svg";

export const BoatCard = ({ id, pictureURL, name, price, location, isFeatured, model, year }) => {
  return (
    <Link to={`/boat-detail/${id}`} title={name}>
      <div className="my-boat-card">
        {isFeatured && <div className="my-boat-card__featured">Featured</div>}
        {!pictureURL && <img className="my-boat-card__picture" src={boatDefaultIMG} alt="Boat" />}
        {pictureURL && <img className="my-boat-card__picture" src={pictureURL} alt="Boat" />}
        <div className="my-boat-card-content">
          <p className="my-boat-card__title">{name}</p>
          <div className="my-boat-card-item">
            <p className="my-boat-card-item__text">{model}</p>
          </div>
          <div className="my-boat-card-item">
            <div className="my-boat-card-item__icon">
              <PriceIcon />
            </div>
            <p className="my-boat-card-item__text">{price}</p></div>
            <div className="my-boat-card-item">
            <div className="my-boat-card-item__icon">
              <YearIcon />
            </div>
            <p className="my-boat-card-item__text">{year}</p></div>
          <div className="my-boat-card-item">
            <div className="my-boat-card-item__icon">
              <LocationIcon />
            </div>
            <p className="my-boat-card-item__text">{location}</p>
          </div>
        </div>
      </div >
    </Link>
  );
}