import { get } from './axios';

const getBuilders = async () => {
    const data = await get('/Common/Builders/Searchlist?filter=', {});
    data.results = data.map(mapSearchBuildersToOptions);
    return data;
}

const getRegions = async () => {
    const data = await get('/Common/Location/Region', {});
    data.results = data.map(mapSearchRegionsToOptions);
    return data;
}

const getCountries = async (regionId) => {
    const data = await get(`/Common/Location/Region/${regionId}/Country`, {});
    data.results = data.map(mapSearchCountriesToOptions); 
    return data;
}

const getStates = async (countryId) => {
    const data = await get(`/Common/Location/Country/${countryId}/State`, {});
    data.results = data.map(mapSearchStatesToOptions);
    return data;
}

const getCities = async (stateId) => {
    const data = await get(`/Common/Location/State/${stateId}/City`, {});
    data.results = data.map(mapSearchCitiesToOptions);
    return data;
}
  
const mapSearchBuildersToOptions = (builder) => ({
    id: builder.BuilderID,
    label: builder.BuilderName,
});

const mapSearchRegionsToOptions = (region) => ({
    id: region.SubRegionID,
    label: region.SubRegion,
});

const mapSearchCountriesToOptions = (country) => ({
    id: country.CountryID,
    label: country.Country,
});

const mapSearchStatesToOptions = (state) => ({
    id: state.StateID,
    label: state.State,
});

const mapSearchCitiesToOptions = (city) => ({
    id: city.CityName,
    label: city.CityName,
});


const VesselFilter = { getBuilders, getRegions, getCountries , getStates, getCities };

export default VesselFilter;